<template>
  <div class="form-approval">
    <v-btn
      cols="12"
      color="grey"
      text
      dark
      small
      style="position:relative; top:10px; left:10px;"
      @click="$router.push('/meeting-activity/request-list')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div v-if="getIsLoading === false">
      <div v-if="permission.read_perm === 1">
        <v-card elevation="3" class="mx-auto form-approval-card">
          <v-form ref="headerForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-toolbar flat style="border-radius:10px 10px 0 0;">
                  <v-btn
                    type="button"
                    class="mx-2 elevation-4 request-detail-title-icon"
                    dark
                    large
                    color="cyan"
                  >
                    <v-icon large dark>
                      mdi-notebook-multiple
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title class="request-detail-title"
                    >FORM APPROVAL
                    <v-tooltip bottom color="grey">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          text
                          icon
                          color="grey darken-2"
                          @click="refresh"
                        >
                          <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>refresh page</span>
                    </v-tooltip></v-toolbar-title
                  >
                  <v-spacer />
                  <v-toolbar-title
                    class=""
                    :style="
                      `border-left:1px solid #e0e0e0; border-right:1px solid #e0e0e0; padding:20px 47px; font-weight:bold; color:${statusColor};`
                    "
                    >{{ getBookingDetail.status.name }}</v-toolbar-title
                  >
                </v-toolbar>
                <v-toolbar flat style="border-radius:10px 10px 0 0;">
                  <div
                    v-if="permission.update_perm === 1"
                    style="display:flex; flex-direction:row;"
                  >
                    <div v-if="getBookingDetail.status.id === 0">
                      <v-btn
                        v-if="isAllow"
                        dense
                        tile
                        elevation="0"
                        small
                        type="button"
                        color="green lighten-1"
                        class=" white--text"
                        style="width:80px; margin-right:10px;"
                        :loading="loading"
                        :disabled="loading"
                        @click="confirm(1)"
                      >
                        Approve
                      </v-btn>
                      <!-- <v-btn
                        v-if="isAllow"
                        dense
                        tile
                        elevation="0"
                        small
                        type="button"
                        color="red lighten-1"
                        class="white--text"
                        style="width:80px;"
                        :loading="loading"
                        :disabled="loading"
                        @click="confirm(-1)"
                      >
                        Reject
                      </v-btn> -->
                    </div>
                    <div v-if="this.getBookingDetail.status.id !== -1">
                      <v-btn
                        dense
                        tile
                        elevation="0"
                        small
                        type="button"
                        color="red lighten-1"
                        class="white--text"
                        style="width:80px;"
                        :loading="loading"
                        :disabled="loading"
                        @click="cancelForm()"
                      >
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-divider />

            <v-container>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="3">
                  <v-text-field
                    v-model="form.employee_name"
                    label="PIC"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="3">
                  <v-text-field v-model="form.email" label="Email" readonly />
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    v-model="form.department_name"
                    label="Department"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    v-model="form.booking_no"
                    label="Book No"
                    readonly
                    style="font-size:12px;"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    v-model="form.room"
                    :items="getRoomDropdown"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Ruang Meeting (Diajukan)"
                    return-id
                    style="margin-bottom:30px;"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    disabled
                    v-model="form.room"
                    :items="getRoomDropdown"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Ruang Meeting (Perubahan)"
                    return-id
                    :readonly="!isEdit"
                    :clearable="isEdit"
                    style="margin-bottom:30px;"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-text-field
                    :value="form.start_use"
                    label="Mulai Penggunaan"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-text-field
                    :value="form.end_use"
                    label="Selesai Penggunaan"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    v-model="form.meeting_type"
                    :items="[
                      { id: 1, name: 'Internal' },
                      { id: 2, name: 'Eksternal' },
                      { id: 3, name: 'Vendor' },
                      { id: 4, name: 'Direksi' },
                      { id: 5, name: 'Lainnya' }
                    ]"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Jenis Meeting"
                    return-id
                    style="margin-bottom:30px;"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    type="number"
                    v-model="form.number_of_attendance"
                    label="Jumlah Peserta"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="form.description"
                    outlined
                    readonly
                    dense
                    clear-icon="mdi-close-circle"
                    label="Keterangan"
                    style="margin-top:5px; "
                    value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, ullam? At perspiciatis culpa autem nostrum, assumenda, sequi ea atque deleniti id, nihil soluta reiciendis quae rerum. Dicta minima architecto possimus."
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="note_from_hr"
                    outlined
                    dense
                    :readonly="
                      Number(this.getUserProfile.employee.department.id) !==
                        2 || !isAllow
                    "
                    clear-icon="mdi-close-circle"
                    label="Keterangan HRD"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row v-if="this.getUserProfile.employee.department.id === 3">
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="note_from_mis"
                    outlined
                    dense
                    :readonly="
                      this.getUserProfile.employee.department.id !== 3 ||
                        !isAllow
                    "
                    clear-icon="mdi-close-circle"
                    label="Keterangan MIS"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-card
          flat
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:1px; margin-bottom:10px;"
        >
          <v-data-table
            :headers="headers"
            :items="matrix"
            class="elevation-1 mx-auto"
            style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :server-items-length="totalSequenceData"
            :loading="matrix.length === 0"
            hide-default-footer
          >
            <template v-slot:[`item.approver_user`]="{ item }">
              <div @click="rowClick(item)" style="cursor:pointer;">
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  v-for="(item, index) in item.approver_user"
                  :key="index"
                >
                  {{ item.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === 1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.rejected`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === -1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <div style="text-align:right; padding-right:5px;">
            <v-btn
              v-if="
                getUserProfile.level.find(
                  ({ application }) => application.id === 1
                ) !== undefined
              "
              color="grey"
              tile
              dark
              small
              dense
              style="font-size:10px; width:100px;"
              @click="fetch"
            >
              <span>Fetch Matrix</span>
            </v-btn>
          </div>
        </v-card>
      </div>
      <div v-else style="height:83vh; padding-top:50px;">
        <AccessDenied />
      </div>
    </div>
    <div
      v-else
      style="position:absolute; top:0; left:0; height:91.5vh; width:100%;"
    >
      <Loader />
    </div>
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      menu_key: 15,
      permission: {},
      application: {
        id: 9,
        name: 'Room Activity'
      },
      loading: false,
      loadingAutocomplete: false,
      form: {},
      note_from_hr: '',
      note_from_mis: '',
      valid: false,
      dateRule: [v => !!v || 'Tgl.Pengajuan is required'],

      itemsPerPage: 10,
      headers: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],
      totalSequenceData: 1,
      options: {},
      isEdit: false,
      statusColor: '',
      isAllow: false,
      isAdmin: false,
      matrix: []
    }
  },
  components: { Loader, AccessDenied },
  created() {
    this.setIsLoading(true)

    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            this.loadPage()
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsLoading',
      'getBookingDetail',
      'getRoomDropdown',
      'getUserProfile'
    ])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'confirmRoomBooking',
      'documentAuthority',
      'approvalMatrixDocumentList',
      'bookingDetail',
      'fetchMatrix',
      'cancelRoomBooking'
    ]),
    ...mapMutations(['setIsLoading', 'setBookingDetail', 'setPermission']),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancel'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    refresh() {
      this.loadPage()
    },
    loadPage() {
      console.log(this.getBookingDetail)
      setTimeout(() => {
        this.initForm()
        this.checkMatrix(this.getBookingDetail)
      }, 1000)
    },
    checkMatrix(data) {
      this.authorityCheck(data)
      this.matrixCheck(data)
    },
    authorityCheck(data) {
      this.documentAuthority(data)
        .then(res => {
          console.log(res)
          if (res.status_code !== '-99') {
            if (res.is_allow_approve !== 0) {
              this.isAllow = true
            } else {
              this.isAllow = false
            }
          } else {
            this.isAllow = false
          }
        })
        .catch(err => {
          console.log('AUTHORITY ERROR ->', err)
          // this.showMsgDialog(
          //   'error',
          //   err
          //     ? 'Authority check error, please contact an Admin'
          //     : 'Authority check error, please contact an Admin'
          // )
        })
    },
    matrixCheck(data) {
      this.approvalMatrixDocumentList(data)
        .then(res => {
          if (res.status_code === '00') {
            this.matrix = res.data
          } else {
            this.showMsgDialog('error', res.status_msg)
          }
        })
        .catch(err => {
          console.log('AUTHORITY ERROR ->', err)
          // this.showMsgDialog(
          //   'error',
          //   err
          //     ? 'Authority check error, please contact an Admin'
          //     : 'Authority check error, please contact an Admin'
          // )
        })
    },
    initForm() {
      this.setIsLoading(false)
      this.form = {
        booking_no: this.getBookingDetail.booking_no,
        company_name: this.getBookingDetail.company_name,
        confirm_hr_by_name: this.getBookingDetail.confirm_hr_by_name,
        confirm_mis_by_name: this.getBookingDetail.confirm_mis_by_name,
        confirm_status_hr: this.getBookingDetail.confirm_status_hr,
        confirm_status_mis: this.getBookingDetail.confirm_status_mis,
        created_at: this.getBookingDetail.created_at,
        created_by_name: this.getBookingDetail.created_by_name,
        department_name: this.getBookingDetail.department_name,
        description: this.getBookingDetail.description,
        email: this.getBookingDetail.email,
        employee_name: this.getBookingDetail.employee_name,
        end_use: new Date(this.getBookingDetail.end_use).toLocaleString(),
        id: this.getBookingDetail.id,
        meeting_type: this.getBookingDetail.meeting_type.id,
        meeting_type_other: this.getBookingDetail.meeting_type_other,
        number_of_attendance: this.getBookingDetail.number_of_attendance,
        room:
          this.getBookingDetail.room !== null
            ? this.getBookingDetail.room.id
            : 0,
        start_use: new Date(this.getBookingDetail.start_use).toLocaleString(),
        status: this.getBookingDetail.status
        // updated_at: this.getBookingDetail.updated_at,
        // updated_by_name: this.getBookingDetail.updated_by_name
      }
      this.note_from_hr =
        this.getBookingDetail.note_from_hr !== null
          ? this.getBookingDetail.note_from_hr
          : ''
      this.note_from_mis =
        this.getBookingDetail.note_from_mis !== null
          ? this.getBookingDetail.note_from_mis
          : ''
      this.setColor(this.form.status.name)
    },
    editForm() {
      this.isEdit = true
    },
    rowClick(item) {
      console.log(item)
    },
    setColor(val) {
      switch (val) {
        case 'Approved':
          this.statusColor = 'green'
          break
        default:
          this.statusColor = 'rgba(0,0,0,0.5)'
          break
      }
    },
    confirm(val) {
      this.setIsLoading(true)
      const form = {
        document_id: this.getBookingDetail.approval_matrix[0].document_id,
        note_from_hr: this.note_from_hr,
        note_from_mis: this.note_from_mis,
        status: val
      }
      this.confirmRoomBooking(form)
        .then(res => {
          console.log(res)
          if (res.status_code !== '-99') {
            this.showMsgDialog('success', res.status_msg)
            if (this.getBookingDetail.status.id === 0) {
              if (
                this.getUserProfile.employee.email === 'hrd@sanqua.co.id' ||
                this.getUserProfile.employee.email ===
                  'smiresepsionis@gmail.com'
              ) {
                this.sendNotifToTelegram()
              }
            }
          } else {
            if (res.status_msg === 'Parameter value has problem') {
              this.showMsgDialog('error', res.error_msg[0].msg)
            } else {
              this.showMsgDialog('error', res.status_msg)
            }
          }
          this.reloadPage()
          this.setIsLoading(false)
        })
        .catch(err => {
          this.reloadPage()
          this.setIsLoading(false)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },
    fetch() {
      this.matrix = []
      this.fetchMatrix(this.getBookingDetail)
        .then(res => {
          if (res.status_code !== '-99') {
            this.showMsgDialog('success', res.status_msg)
            this.reloadPage()
          } else {
            if (res.status_msg === 'Parameter value has problem') {
              this.showMsgDialog('error', res.error_msg[0].msg)
            } else {
              this.showMsgDialog('error', res.status_msg)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },
    reloadPage() {
      this.bookingDetail()
        .then(res => {
          console.log(res)
          if (res.status_code === '00') {
            this.setBookingDetail(res)
            this.checkMatrix(this.getBookingDetail)
          } else {
            console.log(res.status_code)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },
    cancelForm() {
      this.showMsgDialog('question', 'Cancel this request').then(res => {
        if (res.isConfirmed) {
          this.setIsLoading(true)
          this.cancelRoomBooking(this.getBookingDetail)
            .then(res => {
              if (res.status_code === '00') {
                this.showMsgDialog('success', res.status_msg)
                this.reloadPage()
              } else {
                this.showMsgDialog('error', res.status_msg)
              }
              this.setIsLoading(false)
            })
            .catch(err => {
              this.setIsLoading(false)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, please contact an Admin'
                  : 'Something went wrong, please contact an Admin'
              )
            })
        } else if (res.dismiss === 'cancel') {
        } else if (res.dismiss === 'backdrop') {
        }
      })
    },
    async sendNotifToTelegram() {
      const instance = axios.create()
      const apiToken = process.env.VUE_APP_TELEGRAM_TOKEN_API_BOT
      const chatID = process.env.VUE_APP_ID_CHAT
      const msg = `<b>REQUEST PENGGUNAAN RUANG MEETING</b>\n<pre>Request No: ${
        this.getBookingDetail.booking_no
      }</pre>\n<pre>PIC: ${
        this.getBookingDetail.employee_name
      }</pre>\n<pre>Department: ${
        this.getBookingDetail.department_name
      }</pre>\n<pre>Room: ${this.getBookingDetail.room.name} (lt ${
        this.getBookingDetail.room.level
      })</pre>\n<pre>Start Use: ${new Date(
        this.getBookingDetail.start_use
      ).toLocaleString('id', {
        timeZoneName: 'short'
      })}</pre>\n<pre>End Use: ${new Date(
        this.getBookingDetail.end_use
      ).toLocaleString('id', {
        timeZoneName: 'short'
      })}</pre>\n<pre>Meeting Type: ${
        this.getBookingDetail.meeting_type.name
      }</pre>\n<pre>Number of Attendance: ${
        this.getBookingDetail.number_of_attendance
      }</pre>\n<pre>Description: ${
        this.getBookingDetail.description
      }</pre>\n<pre>Status: WAITING APPROVAL MIS</pre>\n\nFYI Guys. Please open this link below to approve. \n👇👇👇👇👇👇\n<a href="https://e-sanqua.sanquawater.co.id/meeting-activity/request-list">https://e-sanqua.sanquawater.co.id/meeting-activity/request-list</a>`

      await instance.post(
        `https://api.telegram.org/bot${apiToken}/sendMessage`,
        {
          chat_id: chatID,
          text: msg,
          parse_mode: 'HTML'
        }
      )
    }
  }
}
</script>

<style lang="scss" src="">
.form-approval {
  position: relative;
  z-index: 2;
  .form-approval-card {
    padding: 10px 15px 20px 15px;
    width: 95%;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.95);
    .request-detail-title-icon {
      position: relative;
      top: -20px;
      left: -10px;
      height: 80px;
      width: 80px;
    }
  }
}
@media (max-width: 576px) {
  .form-approval {
    .form-approval-card {
      .request-detail-title-icon {
        display: none;
      }
      .request-detail-title {
        font-size: 16px;
      }
    }
  }
}
</style>
